<template>
  <v-row class="mt--10">
    <!-- Start Single Counterup  -->
    <v-col md="3" sm="3" v-for="(counter, i) in counterUpContent" :key="i">
      <div class="rn-counterup counterup_style--2">
        <h5 class="counter count">
          <VisibilitySensor @change="onChange">
            <countTo
                :endVal="status ? counter.endVal : 0"
                :autoplay="countUp"
                :duration="3000"
            ></countTo>
          </VisibilitySensor>
          {{ counter.suffix }}
        </h5>
        <h4 class="description">
          {{ counter.desc }}
        </h4>
      </div>
    </v-col>
    <!-- Start Single Counterup  -->
  </v-row>
</template>

<script>
import VisibilitySensor from "vue-visibility-sensor";
import countTo from "vue-count-to";
import axios from "axios";

export default {
  components: {
    countTo,
    VisibilitySensor,
  },
  data() {
    return {
      countUp: true,
      status: false,
      counterUpContent: [],
    };
  },
  created() {
    this.fetchStatistics();
  },
  methods: {
    fetchStatistics() {
      axios
          .get("https://api.github.com/users/nicokempe")
          .then(response => {
            this.counterUpContent = [
              {
                endVal: response.data.public_repos,
                desc: "Public GitHub repositories",
              },
              {
                endVal: response.data.followers,
                desc: "GitHub Followers",
              },
              {
                endVal: 4437,
                desc: "Commits in 2023",
              },
              {
                endVal: response.data.public_gists,
                desc: "Public GitHub Gists",
              },
            ];
          })
          .catch(error => {
            console.log(error);
          });
    },
    onChange(isVisible) {
      if (isVisible) {
        this.status = true;
      }
    },
  },
};
</script>
