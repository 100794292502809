<template>
  <div>
    <!-- Start Header Area -->
    <HeaderSecondary>
          <img
            slot="logo"
            src="@/assets/images/custom/logo.webp"
            alt="Logo"
            height="64" width="64"
          />

    </HeaderSecondary>
    <!-- End Header Area -->

    <!-- Start 404 Page  -->
    <div class="error-page-inner bg_color--4">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner">
              <h1 class="heading-title theme-gradient">404!</h1>
              <h3 class="sub-title">Page not found</h3>
              <!-- <span>The page you were looking for could not be found.</span> -->
              <span></span>
              <div class="error-button">
                <router-link class="rn-button-style--2 btn_solid btn-size-sm" to="/"
                  >Back To Homepage</router-link
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End 404 Page  -->

    <div class="bg_color--4">
      <Footer />
    </div>
    
  </div>
</template>

<script>
  import HeaderSecondary from "../components/custom/static/HeaderSecondary";
  import Footer from "../components/custom/static/Footer";
  export default {
    components: {
      HeaderSecondary,
      Footer,
    },
    data() {
      return {};
    },
  };
</script>
