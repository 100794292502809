<template>
  <footer class="footer-area footer-default">
    <div class="footer-wrapper">
      <v-row align="end" class="row--0">
        <v-col md="4" sm="12" cols="12">
          <div class="footer-left">
            <div class="inner">
              <span>Need my help?</span>
              <h2>
                Let's get <br />
                in touch
              </h2>

                <!-- <router-link class="rn-button-style--2" to="/contact">
                  <span>Contact Us</span>
                </router-link> -->

                <p v-for="(mail, i) in mailList" :key="i">
                  <a class="rn-button-style--2" :href="mail.to">Contact Me</a>
                </p>

            </div>
          </div>
        </v-col>
        <!-- End .col -->
        <v-col md="8" sm="12" cols="12">
          <div class="footer-right" data-black-overlay="6">
            <v-row>
              <!-- Start Single Widget -->
              <v-col lg="6" sm="6" cols="12">
                <div class="footer-widget">
                  <h4>Important Pages</h4>
                  <ul class="ft-link">
                    <li v-for="(nav, i) in navList" :key="i">
                      <router-link :to="nav.to">{{ nav.navItem }}</router-link>
                    </li>
                  </ul>
                </div>
              </v-col>
              <!-- End Single Widget  -->
              <!-- Start Single Widget -->
              <v-col lg="6" sm="6" cols="12" class="mt_mobile--30">
                <div class="footer-widget">
                  <h4>Contact</h4>
                  <ul class="ft-link">
                    <li v-for="(mail, i) in mailList" :key="i">
                      <a :href="mail.to">{{ mail.mailItem }}</a>
                    </li>
                  </ul>

                  <div class="social-share-inner">
                    <ul
                      class="social-share social-style--2 d-flex justify-content-start liststyle mt--15"
                    >
                      <li v-for="(social, i) in socialList" :key="i">
                        <a :href="social.url" target="_blank">
                          <i class="fab" :class="social.icon"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </v-col>
              <!-- End Single Widget  -->

              <v-col lg="12">
                <div class="copyright-text">
                  <p>
                    Copyright © {{ new Date().getFullYear() }} Nico Kempe.
                    All Rights Reserved.
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <!-- End .col -->
      </v-row>
    </div>
  </footer>
</template>

<script>
  export default {
    data() {
      return {
        socialList: [
          {
            icon: "fa-github",
            url: "https://github.com/nicokempe/",
          },
          {
            icon: "fa-linkedin-in",
            url: "https://www.linkedin.com/in/nico-kempe/",
          },
          {
            icon: "fa-twitter",
            url: "https://twitter.com/nico_kempe/",
          },
          {
            icon: "fa-instagram",
            url: "https://instagram.com/nico_kempe/",
          },
          {
            icon: "fa-discord",
            url: "https://discord.com/invite/t9frQmmqPe",
          },
          {
            icon: "fa-youtube",
            url: "https://www.youtube.com/channel/UCulkLxuHnYyzt-wtrLclVKg",
          },
          {
            icon: "fa-twitch",
            url: "https://www.twitch.tv/nicovrny",
          },
          {
            icon: "fa-spotify",
            url: "https://open.spotify.com/user/ayp3biiz4ckcwzwnmbo3ic87a?si=b9d5460d5113457b",
          },
          /* Ich verwende GitLab nicht mehr
          {
            icon: "fa-gitlab",
            url: "https://gitlab.com/nicokempe",
          },*/
          /* Fiverr Icon gibts bei FontAwesome noch nicht
          {
            icon: "fa-fiverr",
            url: "https://fiverr.com/nicovrny",
          },*/
          /* TeamSpeak läuft im Moment nicht
          {
            icon: "fa-teamspeak",
            url: "ts3server://nicokempe.de",
          },*/
        ],
        navList: [
          {
            navItem: "Imprint",
            to: "/imprint",
          },
          {
            navItem: "Privacy Policy",
            to: "/privacy-policy",
          },
          /* Vorerst raus, weil sie noch nicht benötigt werden. 
          {
            navItem: "Terms of Use",
            to: "/terms-of-use",
          }, */
          {
            navItem: "Credits",
            to: "/credits",
          },
        ],
        mailList: [
          {
            mailItem: "kontakt@nicokempe.de",
            to: "mailto:kontakt@nicokempe.de",
          },
        ],
      };
    },
  };
</script>
