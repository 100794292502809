<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="slick-space-gutter--15">
          <VueSlickCarousel
            v-bind="settings"
            class="rn-slick-activation slick-dotted rn-slick-dot mt--10"
          >
            <!-- Start Single Portfolio  -->
            <div
              class="portfolio mt--30 mb--20"
              v-for="(item, i) in portfolioContent"
              :key="i"
            >
              <div class="thumbnail-inner">
                <v-img class="thumbnail" :src="item.src"></v-img>
                <v-img class="bg-blr-image" :src="item.src"></v-img>
              </div>
              <div class="content">
                <div class="inner">
                  <p>{{ item.meta }}</p>
                  <h4>
                    <a :href="item.url">{{ item.title}}</a>
                  </h4>
                  <div class="portfolio-button">
                    <a :href="item.url" class="rn-btn">{{ item.buttonText }}</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Single Portfolio  -->
          </VueSlickCarousel>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import VueSlickCarousel from "vue-slick-carousel";
  export default {
    components: {
      VueSlickCarousel,
    },
    data() {
      return {
        portfolioContent: [
          {
              src: require("@/assets/images/custom/portfolio/dynasty/dynasty-screenshot.webp"),
              meta: "Game (WIP)",
              title: "Project: DYNASTY",
              url: "https://github.com/project-dynasty",
              buttonText: "More",
          },
          {
              src: require("@/assets/images/custom/portfolio/company-dashboard/company-dashboard-screenshot-1.webp"),
              meta: "Enterprise Solution",
              title: "Dashio",
              url: "https://www.dashio.net/",
              buttonText: "More",
          },
          {
              src: require("@/assets/images/custom/portfolio/company-dashboard/company-dashboard-screenshot-1.webp"),
              meta: "Enterprise Solution (WIP)",
              title: "Company Dashboard",
              url: "https://www.company-dashboard.net/",
              buttonText: "More",
          },
          /* {
            src: require("@/assets/images/custom/portfolio/verany.webp"),
            meta: "Minecraft Network (Sold)",
            title: "Verany",
            url: "/portfolio/verany",
            buttonText: "More",
          },
          {
            src: require("@/assets/images/custom/portfolio/profiles/dark_profiles-github.webp"),
            meta: "Open Source project (WIP)",
            title: "Profiles",
            url: "https://github.com/nicokempe/Profiles",
            buttonText: "More",
          }, */
          {
            src: require("@/assets/images/custom/portfolio/discord-bot/discord-bot-screenshot.webp"),
            meta: "Open Source project",
            title: "Discord Bot",
            url: "https://github.com/nicokempe/discord-bot",
            buttonText: "More",
          },
          {
              src: require("@/assets/images/custom/portfolio/docker-deploy-agent/docker-deploy-agent-screenshot.webp"),
              meta: "Open Source project",
              title: "Docker Deploy Agent",
              url: "https://github.com/nicokempe/docker-deploy-agent",
              buttonText: "More",
          },
          {
              src: require("@/assets/images/custom/portfolio/docker-deploy-agent/docker-deploy-agent-screenshot.webp"),
              meta: "Open Source project",
              title: "Discord Activity Tracker",
              url: "https://github.com/nicokempe/discord-tracker",
              buttonText: "More",
          }
        ],
        // for all works
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          spaceBetween: 15,

          responsive: [
            {
              breakpoint: 890,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 770,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 490,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
          ],
        },
      };
    },
  };
</script>

<style>
.thumbnail-inner {
  filter: blur(5px);
}
</style>
