<template>
  <div class="active-dark">
    <!-- Start Header Area -->
    <HeaderMain />
    <!-- End Header Area -->
    <!-- Start Slider Area  -->
    <div class="rn-slider-area poss_relative" id="home">
      <div
        class="slide personal-portfolio-slider slider-style-3 slider-paralax bg_image bg_image--25 d-flex align-center"
      >
        <BannerThree>
          <span slot="designation">SOFTWARE DEVELOPER</span>
          <h1 class="heading-title" slot="heading-title">
            <span>Hello, I’m</span> Nico
          </h1>
        </BannerThree>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start About Area  -->
    <div class="section about-area rn-section-gap bg_color--5" id="about">
      <div class="about-wrapper">
        <div class="container">
          <AboutTabs>
            <img
              slot="thum-img"
              class="w-100"
              src="@/assets/images/custom/nico-kempe.webp"
              alt="About Images"
            />
          </AboutTabs>
        </div>
      </div>
    </div>
    <!-- Stop About Area  -->

    <!-- Start Portfolio Area -->
    <div
      class="section rn-portfolio-area rn-section-gap bg_color--1"
      id="portfolio"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="section-title text-center mb--20 mb_sm--0">
              <h2 class="heading-title">Portfolio</h2>
              <p>
                I've worked on a wide range of projects utilizing a variety of technologies all through the past, but these are the ones that mean the most to me:
              </p>
            </div>
          </v-col>
        </v-row>
        <Portfolio />
      </v-container>
    </div>
    <!-- End Portfolio Area -->

    <!-- Start Interests Area
    <div
      class="section interests-area rn-interests-area rn-section-gap bg_color--1"
      id="interests"
    >
      <div class="container">
        <v-row>
          <v-col lg="12">
            <div
              class="section-title interests-style--3 text-center mb--20 mb_sm--0 md_md--0"
            >
              <h2 class="heading-title">My Interests</h2>
              <p>
                Of course there is much more to this, but the following are the interests that are most important to me personally.
              </p>
            </div>
          </v-col>
        </v-row>
        <InterestsThree />
      </div>
    </div>
    Start Interests Area  -->

    <!-- Start countreupup Area  -->
    <!-- <div class="rn-countreupup-area rn-section-gap bg_image bg_image--27 bg-position-fixed counterup_style--3 text-white" data-black-overlay="6"> -->
    <div class="rn-countreupup-area rn-section-gap bg_color--5">
      <v-container>
        <CounterTwo />
      </v-container>
    </div>
    <!-- Start countreupup Area  -->

    <!-- Start Featured Area  -->
    <div class="rn-portfolio-area rn-section-gap bg_color--1" id="featured">
      <div class="portfolio-sacousel-inner pb--30">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="section-title text-center mb--20 mb_sm--0">
                <h2 class="heading-title">Featured</h2>
                <p>
                  Here are some musicians, series, companies, projects, technologies and YouTubers that I would like to recommend
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <Featured />
      </div>
    </div>
    <!-- Start Featured Area  -->

    <!-- Start Credits Area -->
    <div class="rn-brand-area rn-section-gap bg_color--5" id="credits">
      <v-container>
        <Credits />
      </v-container>
    </div>
    <!-- End Credits Area -->

    <!-- Start Projects Area
    <div class="section rn-projects-area rn-section-gap bg_color--5" id="projects">
      <v-container>
        <v-row align="end" class="mb--20">
          <v-col lg="6" md="12">
            <div class="section-title text-left">
              <h2>Latest Projects</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration.
              </p>
            </div>
          </v-col>
        </v-row>
        <Projects />
      </v-container>
    </div>
    End Projects Area  -->

    <!-- Start Contact Area
    <div
      class="section rn-contact-area rn-section-gap bg_color--1"
      id="contact"
    >
      <div class="contact-form--1">
        <v-container>
          <Contact>
            <img
              slot="contact-img"
              class="w-100"
              src="@/assets/images/about/about-9.jpg"
              alt="trydo"
            />
          </Contact>
        </v-container>
      </div>
    </div>
    End Contact Area  -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import HeaderMain from "../components/custom/static/HeaderMain";
import BannerThree from "../components/slider/BannerThree";
import AboutTabs from "../components/custom/about/AboutTabs";
import Portfolio from "../components/custom/portfolio/Portfolio";
// import InterestsThree from "../components/interests/InterestsThree";
import CounterTwo from "../components/custom/counter/CounterTwo.vue";
import Featured from "../components/custom/portfolio/Featured";
import Credits from "../components/custom/brand/Credits.vue";
// import Projects from "../components/projects/Projects";
// import Contact from "../components/contact/Contact";
import Footer from "../components/custom/static/Footer";

export default {
  components: {
    HeaderMain,
    BannerThree,
    AboutTabs,
    Portfolio,
    // InterestsThree,
    CounterTwo,
    Featured,
    Credits,
    // Projects,
    // Contact,
    Footer,
  },
  data() {
    return {};
  },
};
</script>
