import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import Home from "../views/Home.vue";
import Missing from "../views/404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: Home,
    meta: {
      title: "Nico Kempe | Portfolio",
    },
    component: () =>
      import("../views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "Nico Kempe | About",
    },
    component: () => import("../views/About.vue"),
  },
  {
    path: "/imprint",
    name: "Imprint",
    meta: {
      title: "Nico Kempe | Imprint",
    },
    component: () =>
      import("../views/legal/Imprint.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    meta: {
      title: "Nico Kempe | PrivacyPolicy",
    },
    component: () =>
      import("../views/legal/PrivacyPolicy.vue"),
  },
  /* Temporär entfernt 
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    meta: {
      title: "Nico Kempe | TermsOfUse",
    },
    component: () =>
      import("../views/legal/TermsOfUse.vue"),
  }, */
  {
    path: "/credits",
    name: "Credits",
    meta: {
      title: "Nico Kempe | Credits",
    },
    component: () =>
      import("../views/legal/Credits.vue"),
  },
  /*{
    path: "/interests-details",
    name: "InterestsDetails",
    meta: {
      title: "Nico Kempe | Interests Details",
    },
    component: () => import("../views/interests/InterestsDetails.vue"),
  },
  {
    path: "/projects-details",
    name: "ProjectsDetails",
    meta: {
      title: "Nico Kempe | Projects Details",
    },
    component: () => import("../views/projects/ProjectsDetails.vue"),
  },*/
  {
    path: "/portfolio/verany",
    name: "Verany",
    meta: {
      title: "Nico Kempe | Portfolio - Verany",
    },
    component: () => import("../views/portfolio/Verany.vue"),
  },
  /*{
    path: "/template",
    name: "Template",
    meta: {
      title: "Nico Kempe | Template",
    },
    component: () => import("../views/Template.vue"),
  }, */
  {
    path: "*",
    component: Missing,
    meta: {
      title: "Nico Kempe | 404",
      metaTags: [
        {
          name: 'description',
          content: 'Not available',
        },
        {
          property: 'og:description',
          content: 'Not available',
        },
      ],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
