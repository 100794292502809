<template>
  <ul
    class="social-share social-style--2 color-black d-flex justify-content-start liststyle"
  >
    <li v-for="(social, i) in socialList" :key="i">
      <a :href="social.url" target="_blank"
        ><i class="fab" :class="social.icon"></i
      ></a>
    </li>
  </ul>
</template>
<script>
  export default {
    data() {
      return {
        socialList: [
          {
            icon: "fa-github",
            url: "https://github.com/nicokempe/",
          },
          {
            icon: "fa-linkedin-in",
            url: "https://www.linkedin.com/in/nico-kempe/",
          },
          {
            icon: "fa-twitter",
            url: "https://twitter.com/nico_kempe/",
          },
          {
            icon: "fa-instagram",
            url: "https://instagram.com/nico_kempe/",
          },
          {
            icon: "fa-discord",
            url: "https://discord.com/invite/t9frQmmqPe",
          },
          {
            icon: "fa-youtube",
            url: "https://www.youtube.com/channel/UCulkLxuHnYyzt-wtrLclVKg",
          },
          {
            icon: "fa-twitch",
            url: "https://www.twitch.tv/nico_kempe",
          },
          {
            icon: "fa-spotify",
            url: "https://open.spotify.com/user/ayp3biiz4ckcwzwnmbo3ic87a?si=b9d5460d5113457b",
          },
          /* Ich verwende GitLab nicht mehr
          {
            icon: "fa-gitlab",
            url: "https://gitlab.com/nicokempe",
          },*/
          /* Fiverr Icon gibts bei FontAwesome noch nicht
          {
            icon: "fa-fiverr",
            url: "https://fiverr.com/nicovrny",
          },*/
          /* TeamSpeak läuft im Moment nicht
          {
            icon: "fa-teamspeak",
            url: "ts3server://nicokempe.de",
          },*/
        ],
      };
    },
  };
</script>
