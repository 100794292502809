<template>
  <ul class="brand-style-2">
    <li v-for="(brand, i) in brandImages" :key="i">
      <a :href="brand.url">
        <img :src="brand.src" alt="Logo Images" style="max-width:100%;height:auto;max-height:64px;"/>
      </a>
    </li>
  </ul>
</template>

<script>
  export default {
    data() {
      return {
        brandImages: [
          {
            src: require("@/assets/images/custom/brands/fontawesome.png"),
            url: "https://fontawesome.com/",
          },
          {
            src: require("@/assets/images/custom/brands/vue.png"),
            url: "https://vuejs.org/",
          },
          {
            src: require("@/assets/images/custom/brands/github.png"),
            url: "https://github.com/",
          },
          {
            src: require("@/assets/images/custom/brands/vuetify.png"),
            url: "https://vuetifyjs.com/",
          },
          {
            src: require("@/assets/images/custom/brands/feathericons.png"),
            url: "https://feathericons.com/",
          },
          {
            src: require("@/assets/images/custom/brands/hetzner.png"),
            url: "https://www.hetzner.com",
          },
          {
            src: require("@/assets/images/custom/brands/nodejs.png"),
            url: "https://nodejs.org/en/",
          },
          {
            src: require("@/assets/images/custom/brands/trydo.png"),
            url: "https://themeforest.net/item/trydo-creative-agency-wordpress-theme/28449333",
          },
          {
            src: require("@/assets/images/custom/brands/mongodb.png"),
            url: "https://www.mongodb.com",
          },
        ],
      };
    },
  };
</script>