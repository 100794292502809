<template>
  <div>
    <!-- Start Feature Activation  -->
    <VueSlickCarousel
      v-bind="settings"
      class="rn-slick-activation portfolio-slick-activation item-fluid slick-dotted rn-slick-dot mt--40 mt_sm--40"
    >
      <!-- Start Single Feature  -->
      <div class="portfolio" v-for="(item, i) in featuredContent" :key="i">
        <div class="thumbnail-inner">
          <v-img class="thumbnail" :src="item.src"></v-img>
          <v-img class="bg-blr-image" :src="item.src"></v-img>
        </div>
        <div class="content">
          <div class="inner">
            <p>{{ item.meta }}</p>
            <h4>
              <a :href="item.url">{{ item.title}}</a>
            </h4>
            <div class="portfolio-button">
            <a :href="item.url" class="rn-btn">{{ item.buttonText }}</a>
            </div>
          </div>
        </div>
      </div>
      <!-- End Single Features  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
  import VueSlickCarousel from "vue-slick-carousel";
  export default {
    components: {
      VueSlickCarousel,
    },
    data() {
      return {
        featuredContent: [
          {
            src: require("@/assets/images/custom/features/webp/compressed/designatedsurvivor.webp"),
            meta: "Series",
            title: "Designated Survivor",
            buttonText: "Watch",
            url: "https://youtu.be/N_f1v0Nx5Sw",
          },
          {
            src: require("@/assets/images/custom/features/webp/compressed/eldragon.webp"),
            meta: "Series",
            title: "El Dragón",
            buttonText: "Watch",
            url: "https://youtu.be/h-ScBupZV8k",
          },
          {
            src: require("@/assets/images/custom/features/webp/compressed/unleasheddesign.webp"),
            meta: "YouTuber",
            title: "Unleashed Design",
            buttonText: "Watch",
            url: "https://www.youtube.com/c/UnleashedDesign",
          },
          {
            src: require("@/assets/images/custom/features/webp/compressed/themorpheustutorials.webp"),
            meta: "YouTuber",
            title: "The Morpheus Tutorials",
            buttonText: "Watch",
            url: "https://www.youtube.com/c/TheMorpheus407",
          },
          {
            src: require("@/assets/images/custom/features/webp/compressed/manorlords.webp"),
            meta: "Game",
            title: "Manor Lords",
            buttonText: "Play",
            url: "https://manorlords.com/",
          },
        ],
        // for all works
        settings: {
          dots: true,
          arrows: true,
          infinite: true,
          speed: 500,
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 1263,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 770,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
            {
              breakpoint: 599,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
              },
            },
          ],
        },
      };
    },
  };
</script>
