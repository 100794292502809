<template>
  <v-col lg="12">
    <div class="tab-default">
      <v-tabs v-model="tab" flat hide-slider>
        <v-tab v-for="item in items" :key="item.id" :ripple="false">
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          transition="fade-transition"
          v-for="item in tabContent"
          :key="item.id"
        >
          <v-card flat>
            <v-card-text>
              <div class="single-tab-content">
                <ul>
                  <li v-for="(item, i) in item.content" :key="i">
                    <a v-if="item.url" :href="item.url">
                      {{ item.title }}
                      <span> - {{ item.name }}</span>
                    </a>
                    <router-link v-else to="/about">
                      {{ item.title }}
                      <span> - {{ item.name }}</span>
                    </router-link>
                    {{ item.desc }}
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-col>
</template>
<script>
  export default {
    data() {
      return {
        tab: null,
        items: [
          {
            id: 1,
            name: "Main skills",
          },
          {
            id: 2,
            name: "Education",
          },
          {
            id: 3,
            name: "Experience",
          },
          {
            id: 4,
            name: "Certificates and more",
          },
        ],

        tabContent: [
          {
            id: 1,
            content: [
              {
                title: "Web development",
                name: "started 2019",
                desc: "currently using Nuxt, Tailwind, and TS in JetBrains Tools",
              },
              {
                title: "Server administration",
                name: "started 2017",
                desc: "currently using Termius, DataGrip, and much more",
              },
              {
                title: "Video Design",
                name: "started 2018",
                desc: "by using DaVinci Resolve",
              },
              {
                title: "Game development",
                name: "started 2020",
                desc: "by using Unity and JetBrains Rider",
              },
            ],
          },
          {
            id: 2,
            content: [
              {
                title: "Vocational School Education",
                name: "GDS 2, Böblingen, BW",
                desc: "Oct. 2022 - Sept. 2025 | 1,0 (Intermediate exam.)",
                url: "https://www.gds2.de"
              },
              {
                title: "State-certified economy assistant",
                name: "Schulwerk Mitte, Nürtingen, BW",
                desc: "Sept. 2019 - July 2022 | ⌀2,5",
                url: "https://schulwerk-mitte.de/"
              },
              {
                title: "Fachhochschulreife in economics",
                name: "Schulwerk Mitte, Nürtingen, BW",
                desc: "Sept. 2019 - July 2022 | ⌀2,7",
                url: "https://schulwerk-mitte.de/"
              },
              {
                title: "Realschulabschluss",
                name: "WRS BZ Seefälle, Bonlanden, BW",
                desc: "Sept. 2018 - July 2019 | ⌀2,7",
              },
              {
                title: "Hauptschulabschluss",
                name: "WRS BZ Seefälle, Bonlanden, BW",
                desc: "Sept. 2013 - July 2018 | ⌀2,4",
              },
            ],
          },
          {
            id: 3,
            content: [
              {
                title: "Owner",
                name: "Nico Kempe Einzelunternehmen",
                desc: "01.06.2021 – now in Leinfelden-Echterdingen",
                url: "https://www.nicokempe.eu"
              },
              {
                title: "Trainee for System integration",
                name: "Novatec Consulting GmbH",
                desc: "01.09.2022 – now in Leinfelden-Echterdingen",
                url: "https://www.novatec-gmbh.de"
              },
              {
                title: "Temporary Staff",
                name: "Novatec Consulting GmbH",
                desc: "01.08 – 31.08.2022 in Leinfelden-Echterdingen",
                url: "https://www.novatec-gmbh.de"
              },
              {
                title: "Intern in System integration",
                name: "Novatec Consulting GmbH",
                desc: "19.04 – 21.04.2022 in Leinfelden-Echterdingen",
                url: "https://www.novatec-gmbh.de"
              },
              {
                title: "Software development",
                name: "Sunato GmbH",
                desc: "08.07. - 19.07.2019 in Leinfelden-Echterdingen",
                url: "https://sunato.de/"
              },
              {
                title: "Marketing",
                name: "KPUNKT Technologie Marketing GmbH",
                desc: "16.01. - 20.01.2017 in Stuttgart",
                url: "https://www.kpunkt.net/"
              },
            ],
          },
          {
            id: 4,
            content: [
              {
                title: "Training on the basics of data protection law and the EU General Data Protection Regulation",
                name: "Aug. 2024",
                desc: "intersoft consulting services AG, Online",
                url: "https://www.linkedin.com/in/nico-kempe/details/certifications/1723193712206/single-media-viewer/?type=DOCUMENT&profileId=ACoAADPjNLMB5Y_7qUzwFmOwY9swYRzFjQAeTuw"
              },
              {
                title: "Certified DNS Developer",
                name: "Jun. 2024",
                desc: "NSLookup.io, Online",
                url: "https://www.linkedin.com/in/nico-kempe/details/certifications/1717288584978/single-media-viewer/?profileId=ACoAADPjNLMB5Y_7qUzwFmOwY9swYRzFjQAeTuw"
              },
              {
                title: "Basic course 21",
                name: "Apr. 2024",
                desc: "Deutsches Rotes Kreuz, Online",
                url: "https://media.licdn.com/dms/image/v2/D4E2DAQFtZVhVsC08QQ/profile-treasury-image-shrink_1280_1280/profile-treasury-image-shrink_1280_1280/0/1712963687965?e=1725116400&v=beta&t=Yw2K9q-bLYQXFTffUAiZhCJQYtkFaKbQK8XuUJg5K04"
              },
              {
                title: "“Faster than memory training” - absorb & retain knowledge in record time",
                name: "Apr. 2024",
                desc: "Haufe Akademie, Online",
                url: "https://www.linkedin.com/in/nico-kempe/details/certifications/1715175726008/single-media-viewer/?profileId=ACoAADPjNLMB5Y_7qUzwFmOwY9swYRzFjQAeTuw"
              },
              {
                title: "Giving and receiving feedback professionally",
                name: "Mar. 2024",
                desc: "Haufe Akademie, Online",
                url: "https://www.linkedin.com/in/nico-kempe/details/certifications/1713359545290/single-media-viewer/?profileId=ACoAADPjNLMB5Y_7qUzwFmOwY9swYRzFjQAeTuw"
              },
              {
                title: "RabbitMQ Fundamentals",
                name: "Dec. 2023",
                desc: "LinkedIn Learning, Online",
                url: "https://www.linkedin.com/learning/certificates/a31dbed6b81fef64c25b7ea30da899c93eb3a7b739d930dc278478b9bf94ea4f?u=27933482"
              },
              {
                title: "Practical seminar: Successful with personality",
                name: "Nov. 2023",
                desc: "Haufe Akademie, Online",
                url: "https://www.nicokempe.de/storage/certificates/2023/successful-with-personality.pdf"
              },
              {
                title: "Certified Nuxt Master",
                name: "Sep. 2023",
                desc: "Vue School, Online",
                url: "https://api.masteringnuxt.com/certificates/99189337-4d90-4b4e-aaf5-aa5dab926f3c/download?signature=7d45336ff8fa3fa5bc9e8d6c8fb19bd76a7318d7b8ac2805f42b87e70e1e1c74"
              },
              {
                title: "Training on the basics of data protection law and the EU General Data Protection Regulation",
                name: "Aug. 2023",
                desc: "intersoft consulting services AG, Online",
                url: "https://www.nicokempe.de/storage/certificates/2023/data-privacy-training.pdf"
              },
              {
                title: "Get a handle on Fail2Ban Firewall brute force attacks",
                name: "Jun. 2023",
                desc: "Udemy, Online",
                url: "https://www.udemy.com/certificate/UC-42ef31f7-cf04-4882-b447-fb5097f8885a/"
              },
              {
                title: "Kubernetes Fundamentals",
                name: "Jun. 2023",
                desc: "LinkedIn Learning, Online",
                url: "https://www.linkedin.com/learning/certificates/c2410247828d23c395c712e3295ab3821cac1041af583e1900cb67fd59c4e473"
              },
              {
                title: "Network Security Fundamentals",
                name: "Jun. 2023",
                desc: "LinkedIn Learning, Online",
                url: "https://www.linkedin.com/learning/certificates/a4f0a12143397cb97989307b621b6f3c287d05798023ffc76073659e94c2d7d5"
              },
              {
                title: "Fire safety instruction with practical extinguishing exercise",
                name: "Nov. 2022",
                desc: "Waldenbuch, BW",
                url: "https://www.nicokempe.de/storage/certificates/2022/fire-safety-instruction.pdf"
              }, 
              {
                title: "Data protection training",
                name: "Aug. 2022",
                desc: "DataSolution Thurmann GbR, Online",
                url: ""
              },
              {
                title: "Class representative for the graduating class BKW2",
                name: "2021/22",
                desc: "Schulwerk Mitte, Nürtingen, BW",
                url: "https://schulwerk-mitte.de/"
              },
              {
                title: "First aid training for company first aiders",
                name: "23.11.2019",
                desc: "Nürtingen, BW",
                url: "https://www.nicokempe.de/storage/certificates/2019/first-aid-certificate.pdf"
              },
            ],
          },
        ],
      };
    },
  };
</script>
